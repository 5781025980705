<template>
    <div>
        <customerPc v-if="$store.state.windowWidth > 1000" />
        <customerMb v-else />
    </div>
</template>

<script>
import customerMb from './xwComponents/customerMb.vue'
import customerPc from './xwComponents/customerPc.vue'
export default {
    components:{
        customerMb,
        customerPc
    },
    data(){
        return{

        }
    }
}
</script>