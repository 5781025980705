<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="bg_header">
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t('客服中心') }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <div class="service">
                <!-- <div class="service_Link">
                    {{ $t('立即聯絡') }}
                </div> -->
                <div class="service_list cs">
                    <div class="service_InL">
                        <img src="@/assets/images/xw/icon_Serv_line.svg" alt="">
                    </div>
                    <div class="service_InC">
                        {{ $t('Line客服') }}
                    </div>
                    <div @click="openPage(serviceData?.line)" class="service_InR">
                        {{ $t('加入') }}
                    </div>
                </div>

                <div class="service_list cs">
                    <div class="service_InL">
                        <img src="@/assets/images/xw/Telegram.svg" alt="">
                    </div>
                    <div class="service_InC">
                        {{ $t('Telegram ') }}
                    </div>
                    <div @click="openPage(serviceData?.tg)" class="service_InR">
                        {{ $t('加入') }}
                    </div>
                </div>

                <div class="service_list cs">
                    <div class="service_InL">
                        <img src="@/assets/images/xw/facebook.svg" alt="">
                    </div>
                    <div class="service_InC">
                        {{ $t('Face Book') }}
                    </div>
                    <div @click="openPage(serviceData?.fb)" class="service_InR">
                        {{ $t('加入') }}
                    </div>
                </div>

                <div class="service_list cs">
                    <div class="service_InL">
                        <img src="@/assets/images/xw/youtube.svg" alt="">
                    </div>
                    <div class="service_InC">
                        {{ $t('YouTube') }}
                    </div>
                    <div @click="openPage(serviceData?.ytb)" class="service_InR">
                        {{ $t('前往') }}
                    </div>
                </div>
            </div>
        </div>
        <xwTabbar />

    </div>
</template>

<script>
import xwTabbar from "@/components/xwTabbar.vue";
import {serviceLink} from "@/utils/serviceLink.js";
export default {
    components: {
        xwTabbar,
    },
    data() {
        return {
            serviceData:{

            }
        }
    },
    mounted() {
        this.serviceData = serviceLink
    },
    methods: {
        openPage(url){
            window.open(url)
        },
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
    background-size: 100% auto;
    background-image: url('../../../assets/images/xw/kfzx_bg.png');
    background-repeat: no-repeat;
    background-position: top;
    background-color: #f0eff5;
}

.member_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 40px;
    background-color: #3f9eff;
    z-index: 9999;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;

}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: fit-content;
    margin: 0 auto;
    padding: 0;
    padding-top: 40px;

    @media (max-width:768px) {
        // padding: 15px;
        padding-bottom: 60px;
    }
}

.service {
    position: relative;
    padding-top: 100%;
    width: 100vw;
    min-height: var(--vh);
    padding-bottom: 30px;
    z-index: 1;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-image: url('../../../assets/images/xw/md_tw_777.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center top;
        background-color: #67b6de;
        z-index: -1;

        @media (max-width:768px) {
            background-size: 100% auto;
        }

    }
}

.service_Link {
    width: 178px;
    line-height: 49px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border-radius: 99px;
    background-color: rgba(75, 171, 154, .8);
    margin: auto;
    display: table;
    cursor: pointer;
    line-height: 42px;
    margin-bottom: 20px;
    z-index: 1;
}

.service_list {
    background-color: #fff;
    display: none;
    width: 90%;
    height: 70px;
    margin: auto;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 0 3%;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, .03);
    text-decoration: none;
    @media (max-width:768px) {
        height: 50px;
        display: table;
        }
}

.service_InL {
    display: table-cell;
    position: relative;
    width: 12%;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;

    img {
        width: auto;
        height: 28px;
    }
}

.service_InC {
    display: table-cell;
    vertical-align: middle;
    padding-right: 2%;
    padding-left: 4%;
    color: #000;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.service_InR {
    display: table-cell;
    width: 18%;
    color: #4bab9a;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
}
</style>