<template>
    <div class="xw_page_container">
        <div class="member_header">
            <div class="member_title_name " v-if="$store.state.windowWidth > 768">
                {{ $t('客服中心') }}
            </div>

            <div class="bg_header" v-else>
                <div class="header_content">
                    <button @click="goBack" type="button" class="btn_back"></button>
                    <span>{{ $t('客服中心') }}</span>
                </div>
            </div>
        </div>

        <div class="member_content">
            <div class="main_content" :style="containerStyle">
                <a :href="serviceData?.line" class="service_btn ">
                    {{ $t('立即聯絡') }}
                </a>

                <!-- <ul class="btn_box">
                    <li class="btn_box_in btn_container_left">
                        <span class="btn_contact">
                            <img src="@/assets/images/xw/serviceicon_1.png" alt="">
                            <span class="list_box1">
                                <span class="list_title">{{ $t('客服專線') }}</span>
                                <span>+63276215730</span>
                            </span>
                        </span>
                        <span class="btn_contact">
                            <img src="@/assets/images/xw/serviceicon_2.png" alt="">
                            <span class="list_box1">
                                <span class="list_title">{{ $t('電子郵件') }}</span>
                                <span>+63276215730</span>
                            </span>
                        </span>
                    </li>

                    <li class="btn_contact btn_center">
                        <div class="line_qr"></div>
                        <span class="list_box2">
                            <span class="list_title">{{ $t('LINE客服') }}</span>
                            <img style="margin-top: 15px;" src="@/assets/images/xw/serviceicon_4.png" alt="">
                            <span>@leo666</span>
                        </span>
                    </li>

                    <li class="btn_contact btn_right ">
                        <span class="list_title">{{ $t('回電服務') }}</span>
                        <img style="margin-top: 25px;" src="@/assets/images/xw/serviceicon_3.png" alt="">
                    </li>
                </ul> -->
            </div>
        </div>

    </div>
</template>

<script>
import VueQr from 'vue-qr'
import { serviceLink } from "@/utils/serviceLink.js";
export default {
    components: {
        VueQr
    },
    data() {
        return {
            tcContent: '',
            scale: 1,
            downloadUrl: '',
            serviceData: {}
        }
    },
    computed: {
        containerStyle() {
            return {
                transform: `scale(${this.scale})`,
                transformOrigin: 'top left',
            };
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateScale);
        this.updateScale(); // 初始化呼叫
        this.initDownUrl()
        this.serviceData = serviceLink
    },
    methods: {
        updateScale() {
            const containerWidth = 1000;
            const windowWidth = window.innerWidth;
            if (windowWidth < containerWidth) {
                this.scale = windowWidth / containerWidth;
            } else {
                this.scale = 1;
            }
        },
        initDownUrl() {
            let rule = this.$helper.get("rule");
            const ua = navigator.userAgent
            const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
            if (isSafari) {
                this.downloadUrl = rule.ios_download_url
            } else {
                this.downloadUrl = rule.android_download_url
            }
        },
        goBack() {
            this.$router.go(-1);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScale);
    }
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
        z-index: 10;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    max-width: 100vw;
    overflow: hidden;
    margin: 0 auto;

    @media (max-width:768px) {
        padding: 40px 8px;
    }
}

.main_content {
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    font-size: .8em;
    line-height: 1.7em;
    word-break: break-all;
    text-align: justify;
    color: #000;
    margin: 20px auto;
    width: 958px;
    height: 600px;
    border: 1px solid #d5d5d5;
    background: url('../../../assets/images/xw/custservice_bg.png') no-repeat #fff;

    @media (max-width:768px) {}
}

.service_btn {
    background: url('../../../assets/images/xw/service_btn.png');
    width: 276px;
    height: 80px;
    color: #0f3466;
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    line-height: 63px;
    right: 84px;
    top: 243px;
    position: absolute;
    cursor: pointer;

    &:hover {
        background-position: 0 160px;
    }
}

.btn_box {
    position: absolute;
    bottom: 21px;
    width: 918px;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    margin: auto;
}

.btn_box_in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn_contact {
    background: rgba(251, 251, 251, 0.75);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.btn_center.btn_contact {
    width: 350px;
    height: 209px;
    justify-content: flex-end;
}

.btn_container_left {
    .btn_contact {
        width: 350px;
        height: 97px;
        justify-content: center;
    }

    img {
        margin-right: 26px;
    }
}

.list_box1 {
    width: 201px;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
    height: 42px;
    justify-content: space-between;
}

.list_title {
    color: #3a9b7c;
}

.line_qr {
    position: relative;
    width: 137px;
    height: 136px;
}

.list_box2 {
    width: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 133px;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
}

.btn_contact.btn_right {
    width: 188px;
    height: 209px;
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
</style>